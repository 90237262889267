import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../components/dialogs/alert-dialog/alert-dialog.component';
import { AlertDialogConfig } from '../../components/dialogs/dialog.model';
import { ErrorResult } from '../../models/response.model';

@Injectable({
    providedIn: 'root',
})
export class ApiErrorHandlerService {
    constructor(private matDialog: MatDialog) {}

    handleError(error: ErrorResult): void {
        const errorMessages: string[] = [];

        if (error.error.errors) {
            Object.entries(error.error.errors).forEach(([key, messages]) => {
                if (Array.isArray(messages)) {
                    errorMessages.push(`${key}: ${messages.join(', ')}`);
                } else {
                    console.warn(`Expected messages to be an array for key: ${key}`);
                }
            });
        }

        if (error?.error?.messages && Array.isArray(error.error.messages)) {
            error.error.messages.forEach((messageObj: { message: string }) => {
                if (messageObj?.message) {
                    errorMessages.push(messageObj.message);
                }
            });
        }

        if (error?.error?.error?.messages && Array.isArray(error.error.error.messages)) {
            error.error.error.messages.forEach((messageObj: { message: string }) => {
                if (messageObj?.message) {
                    errorMessages.push(messageObj.message);
                }
            });
        }

        const friendlyErrorMessage = errorMessages.length
            ? errorMessages.join('\n\n')
            : 'An unexpected error occurred.';

        const popupConfig = new AlertDialogConfig();
        popupConfig.data = {
            message: friendlyErrorMessage,
        };
        this.matDialog.open(AlertDialogComponent, popupConfig).afterClosed();
    }
}
