import { PermissionEnum } from '../../../../../features/web/admin-management/constants/module-permission.constant';
import { ModuleEnum } from '../../../../../features/web/admin-management/constants/module.constant';
import { SidebarDataModel } from '../models/sidebar-data.model';

export const SIDEBAR_MENUS: SidebarDataModel[] = [
    {
        title: 'User Management',
        url: '/user-management',
        requiredPermission: { module: ModuleEnum.UserModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Checklist Management',
        url: '/checklist-management',
        requiredPermission: { module: ModuleEnum.ChecklistModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Banner Management',
        url: '/banner-management',
        requiredPermission: { module: ModuleEnum.BannerModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Resource Management',
        url: '/resource-management',
        requiredPermission: { module: ModuleEnum.ResourceModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Notification',
        url: '/notification-management',
        requiredPermission: { module: ModuleEnum.NotificationModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Admin Management',
        url: '/admin-management',
        requiredPermission: { module: ModuleEnum.AdminModule, permission: PermissionEnum.Read },
    },
    {
        title: 'Activity Log',
        url: '/activity-log',
    },
];
