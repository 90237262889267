import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { httpInterceptorProviders } from './features/auth/http-interceptors/http-interceptors';
import { AuthLayoutRoutingModule } from './layouts/auth-layout/auth-layout-routing.module';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { MsalRedirectComponent } from './msal-redirect.component';

@NgModule({
    declarations: [AppComponent],
    imports: [AppRoutingModule, AuthLayoutModule, AuthLayoutRoutingModule, BrowserModule, StoreModule.forRoot({}, {})],
    providers: [
        DatePipe,
        httpInterceptorProviders,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
