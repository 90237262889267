import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogComponent } from '../dialog.component';
import { AlertPopupModel } from '../dialog.model';

@Component({
    selector: 'app-alert-dialog',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatIconModule],
    templateUrl: './alert-dialog.component.html',
    styleUrl: './alert-dialog.component.scss',
})
export class AlertDialogComponent extends DialogComponent implements OnInit {
    message!: string;
    okLabel!: string;
    iconUrl!: string;

    constructor(
        protected override dialogRef: MatDialogRef<AlertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected override data: AlertPopupModel,
    ) {
        super(dialogRef, data);
    }

    ngOnInit(): void {
        this.message = this.data.message || 'An unexpected error has occurred';
        this.okLabel = this.data.okLabel || 'OK';
        this.iconUrl = this.data.iconUrl || '/assets/icons/warning.svg';
    }
}
