/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../../core/services/storages/storage.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
    constructor(private storageService: StorageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let params = new HttpParams({ fromString: req.params.toString() });
        let headers = req.headers;

        if (params.get('noToken')) {
            params = params.delete('noToken');
        } else if (params.get('refreshToken') || req.url.includes('refreshToken')) {
            params = params.delete('refreshToken');
            headers = headers.set('Authorization', `Bearer ${this.storageService.getUserRefreshToken()}`);
        } else {
            if (!headers.get('Authorization') && !req.url.includes('storage.googleapis')) {
                headers = headers.set('Authorization', `Bearer ${this.storageService.getAccessToken()}`);
            }
        }

        if (req.url.indexOf('/logout') > -1) {
            headers = headers.set('Authorization', `Bearer ${this.storageService.getUserRefreshToken()}`);
        }

        req = req.clone({
            params: params,
            headers: headers,
        });

        return next.handle(req);
    }
}
