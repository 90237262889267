import { EnumConstantInterface, EnumOption } from '../../../../core/models/general.model';

export enum PermissionEnum {
    Create = 1,
    Read = 2,
    Update = 3,
    Delete = 4,
}

export const PERMISSION_OPTION: EnumConstantInterface = {
    [PermissionEnum.Create]: {
        cssClass: '',
        label: 'Create',
    },
    [PermissionEnum.Read]: {
        cssClass: '',
        label: 'Read',
    },
    [PermissionEnum.Update]: {
        cssClass: '',
        label: 'Update',
    },
    [PermissionEnum.Delete]: {
        cssClass: '',
        label: 'Delete',
    },
};

export function getPermissionEnumOption(permission: PermissionEnum): EnumOption {
    const foundOption = PERMISSION_OPTION[permission];
    if (!foundOption) {
        return new EnumOption();
    }
    return foundOption;
}
