<section class="topbar-container z-30 relative">
    <nav
        class="navbar-top fixed top-0 left-0 right-0 z-50 gap-3 border-b border-n0 bg-n0 py-3 px-5 md:px-20 shadow-sm xl:py-4 xxxl:py-6"
        id="topbar">
        <div class="topbar-inner flex items-center justify-between md:justify-end">
            <!-- Burger Icon -->
            <button
                id="sidebar-toggle-btn"
                type="button"
                class="block md:hidden text-3xl z-50 text-primary sidebar-toggle-btn"
                (click)="toggleSidebar()">
                &#9776;
            </button>
            <div class="relative">
                <button type="button" id="navbar-btn" (click)="toggleOpen()">
                    <div class="flex flex-col items-start ml-3">
                        <div class="flex">
                            <div class="font-semibold">{{ loginUserName }}</div>
                            <img src="assets/icons/red-arrow-down.svg" alt="red-arrow-down" class="w-[25px]" />
                        </div>
                        <div>
                            <div class="text-themegrey font-semibold text-sm">{{ loginUserRole }}</div>
                        </div>
                    </div>
                </button>
                <div id="navbar" class="dropdown-container" [ngClass]="{ hidden: !isProfileDropdownOpen }">
                    <div class="p-2.5 cursor-pointer text-primary font-semibold" translate (click)="logout()">
                        Logout
                    </div>
                </div>
            </div>
        </div>
    </nav>
</section>
