import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from '../../../../core/services/api/auth.service';
import { StorageService } from '../../../../core/services/storages/storage.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
    @Input() isSidebarOpen = false;
    @Input() toggleSidebar!: () => void;
    isProfileDropdownOpen = false;
    loginUserName = '';
    loginUserRole = '';

    constructor(
        private storageService: StorageService,
        private router: Router,
        private authService: AuthService,
    ) {}

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        if (
            !document.querySelector('#navbar')!.contains(event.target as Node) &&
            !document.querySelector('#navbar-btn')!.contains(event.target as Node)
        ) {
            this.isProfileDropdownOpen = false;
        }
    }

    ngOnInit(): void {
        this.getLoginDetails();
    }

    toggleOpen(): void {
        this.isProfileDropdownOpen = !this.isProfileDropdownOpen;
    }

    logout(): void {
        this.authService
            .logout()
            .pipe(
                finalize(() => {
                    this.storageService.clear();
                    this.router.navigate(['/login']);
                }),
            )
            .subscribe();
    }

    private getLoginDetails(): void {
        this.loginUserRole = this.storageService.getLoginUserRole();
        this.loginUserName = this.storageService.getLoginUserName();
    }
}
