<aside
    id="sidebar"
    class="fixed top-0 left-0 z-40 w-64 h-screen bg-white shadow-lg pt-[75px] flex flex-col transform transition-transform duration-200 ease-in-out border-r-4 border-[#F9F9F9]"
    [ngClass]="{ 'translate-x-0': isSidebarOpen, '-translate-x-full': !isSidebarOpen }">
    <div class="flex flex-col gap-5 md:gap-10 items-center overflow-y-auto pb-10">
        <img src="assets/images/fnn-logo-text.png" alt="Icon" class="mb-10 md: mb-16" />

        <a
            *ngFor="let menuItem of menuItems"
            class="text-lg font-semibold text-secondary hover:text-primary flex justify-center items-center w-full"
            routerLinkActive="active-link"
            [routerLink]="[menuItem.url]"
            (click)="toggleSidebar()">
            {{ menuItem.title }}
            <span class="red-dot"> </span>
        </a>
    </div>
    @if (IS_DEV) {
        <div class="w-full flex justify-center absolute bottom-0">
            <div class="p-2 text-gray-500 text-xs">
                {{ APP_VERSION }}
            </div>
        </div>
    }
</aside>
