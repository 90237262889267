<mat-dialog-content class="!px-[34px] !pt-7 text-center">
    <div class="flex justify-center align-top">
        <img class="h-20 w-20" alt="Icon" [src]="iconUrl" />
    </div>
    <div class="mat-body text-[#696969] !my-5" [innerText]="message"></div>
</mat-dialog-content>

<mat-dialog-actions align="center" class="!px-[34px] !pb-[34px] !pt-3.5">
    <button type="button" class="btn text-center" mat-dialog-close>{{ okLabel }}</button>
</mat-dialog-actions>
