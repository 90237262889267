import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storages/storage.service';

export const AUTH_GUARD: CanActivateFn = () => {
    const userStorage = inject(StorageService);
    const router = inject(Router);

    const loginUser = userStorage.getLoginUser();

    if (!loginUser) {
        return router.parseUrl('/login');
    }

    return true;
};
