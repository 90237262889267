export const environment = {
    name: 'DEV',
    apiServer: 'https://fnn-preboarding-api-docker-staging-202077838788.asia-southeast1.run.app',
    apiVersion: '/api/admin/v1',
    releaseVersion: 'v0.0.1',
    msalConfig: {
        auth: {
            clientId: 'f56feb64-aa73-4ad1-835c-7739303e4d92',
            authority: 'https://login.microsoftonline.com/ba885d6c-4b6e-45ca-a859-ac65db855b8d',
        },
    },
    apiConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft.com/v1.0/me',
    },
};
