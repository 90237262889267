/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from '../../components/base/base-api.service';
import { DeviceModel } from '../../models/device-model';
import { LoginApiModel } from '../../models/login-model';
import { Result } from '../../models/response.model';
import { StorageService } from '../storages/storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseApiService {
    protected override endPoint = '/User';

    constructor(
        private httpClient: HttpClient,
        private storageService: StorageService,
    ) {
        super(httpClient);
    }

    login(oAuthToken: string, azADAccessToken: string): Observable<any> {
        const body = {
            device: new DeviceModel(this.storageService.deviceId, this.storageService.registrationId),
            provider: AZURE,
            oAuthToken,
        };

        const params = {
            noToken: 'true',
        };

        return this.http
            .post(`${this.fullPath}/adLogin`, body, {
                headers: { Authorization: 'Bearer ' + azADAccessToken },
                params,
            })
            .pipe(
                map((data: any) => {
                    this.storageService.setLoginUser(data.result);
                    return data.result;
                }),
            );
    }

    refreshToken(): Observable<LoginApiModel> {
        const token = this.storageService.getUserRefreshToken();

        if (!token) {
            return throwError({});
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });

        return this.http
            .get<Result<LoginApiModel>>(`${this.fullPath}/refreshToken`, {
                headers: headers,
                params: { noToken: '1', refresh: '1' },
            })
            .pipe(
                map((data) => {
                    const loginUser = this.storageService.getLoginUser();
                    if (!loginUser) {
                        throw new Error('Login user not found');
                    }
                    loginUser.accessToken = data.result.accessToken;
                    loginUser.refreshToken = data.result.refreshToken;

                    this.storageService.setLoginUser(loginUser);
                    return data.result;
                }),
            );
    }

    logout(): Observable<boolean> {
        return this.http.post<Result<boolean>>(`${this.fullPath}/logout`, null).pipe(map((resp) => resp.result));
    }
}

const AZURE = 5;
