import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogModel } from './dialog.model';

@Component({
    selector: 'app-dialog',
    standalone: true,
    imports: [],
    template: '',
})
export class DialogComponent {
    constructor(
        protected dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: BaseDialogModel,
    ) {}

    static getDialogConfig(): MatDialogConfig<BaseDialogModel> {
        return {
            panelClass: 'custom-dialog',
            data: { message: '' },
        };
    }

    protected onCancel(): void {
        this.dialogRef.close(false);
    }

    protected onOkay(): void {
        this.dialogRef.close(true);
    }
}
