import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseApiService {
    protected apiServer = environment.apiServer;
    protected apiVersion = environment.apiVersion;
    protected endPoint = '/';

    protected refreshSubject = new Subject<void>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    refresh$ = this.refreshSubject.asObservable();

    constructor(protected http: HttpClient) {}

    protected get fullPath(): string {
        return `${this.apiServer}${this.apiVersion}${this.endPoint}`;
    }
}
