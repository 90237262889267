import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../core/services/storages/storage.service';
import { SIDEBAR_MENUS } from './constants/sidebar.constant';
import { SidebarDataModel } from './models/sidebar-data.model';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
    @Input() isSidebarOpen = false;
    @Input() toggleSidebar!: () => void;
    menuItems: SidebarDataModel[] = [];
    APP_VERSION = environment.releaseVersion;
    readonly IS_DEV = environment.name.toUpperCase() === 'DEV';

    constructor(private storageService: StorageService) {}

    ngOnInit(): void {
        this.menuItems = this.checkSidebarMenuPermissions();
    }

    checkSidebarMenuPermissions(): SidebarDataModel[] {
        return SIDEBAR_MENUS.filter((item) => {
            if (item.requiredPermission) {
                const { module, permission } = item.requiredPermission;
                return this.storageService.hasPermission(module, permission);
            }
            return true;
        });
    }
}
