import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthModule } from '../../features/auth/auth.module';
import { AuthLayoutRoutingModule } from './auth-layout-routing.module';
import { AuthLayoutComponent } from './auth-layout.component';

@NgModule({
    declarations: [AuthLayoutComponent],
    imports: [AuthLayoutRoutingModule, AuthModule, CommonModule],
})
export class AuthLayoutModule {}
