import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AUTH_ROUTES } from '../../features/auth/auth.routes';

const routes: Routes = [...AUTH_ROUTES];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthLayoutRoutingModule {}
