<div class="flex justify-center lg:justify-start items-center min-h-screen px-[30px] lg:px-0">
    <div
        class="flex flex-col lg:w-1/3 bg-white bg-opacity-85 backdrop-blur-sm px-6 py-16 lg:px-16 rounded-[36px] lg:ml-20 overflow-auto">
        <div class="flex justify-between items-center mb-4 lg:mb-20">
            <img src="/assets/images/fnn-logo.png" alt="Icon" class="w-[100px] lg:w-[150px]" />
        </div>
        <div class="text-xl lg:text-4xl font-bold text-[#004C9D] mb-8 lg:mb-24">
            Welcome to F&N Employee Preboarding Website Admin Portal !
        </div>

        <button
            class="flex justify-center items-center text-center btn font-bold text-xl lg:text-2xl w-[280px] rounded-lg py-2 lg:py-4 whitespace-nowrap"
            type="button"
            (click)="loginRedirect()">
            Log In with SSO
        </button>
        @if (IS_DEV) {
            <div class="w-full flex justify-center">
                <div class="mt-2 text-gray-500 text-xs">
                    {{ APP_VERSION }}
                </div>
            </div>
        }
    </div>
</div>
