export class DeviceModel {
    deviceNo: string;
    deviceType: number;
    registrationId: string;
    guiVersion: string;
    isIosSandBox: boolean;

    constructor(id: string, registrationId?: string) {
        this.deviceNo = id;
        this.deviceType = WEB;
        this.registrationId = registrationId || '';
        this.guiVersion = 'v1.0';
        this.isIosSandBox = true;
    }
}

const WEB = 3;
