import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { LoginApiModel } from '../../../core/models/login-model';
import { AuthService } from '../../../core/services/api/auth.service';
import { StorageService } from '../../../core/services/storages/storage.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        private loginService: AuthService,
        private router: Router,
        private localStorageService: StorageService,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 && (!req.params || !req.params.get('refresh'))) {
                        return this.handle401Error(req, next);
                    }

                    if (err.status === 403 || (err.status === 404 && err.error && err.error.ErrorCode == 5)) {
                        this.localStorageService.clear();
                        this.router.navigateByUrl('/login');
                    }

                    if (err.error) {
                        return throwError(() => err.error);
                    }
                }

                return throwError(() => ({ message: 'Application Error. Please report to our engineer.' }));
            }),
        );
    }

    private handle401Error(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next('');

            return this.loginService.refreshToken().pipe(
                finalize(() => (this.isRefreshing = false)),
                switchMap((result: LoginApiModel) => {
                    this.refreshTokenSubject.next(result.accessToken);
                    return next.handle(this.addToken(req, result.accessToken));
                }),
                catchError((err) => {
                    this.localStorageService.clear();
                    this.router.navigateByUrl('/login');
                    return throwError(() => err);
                }),
            );
        }

        return this.refreshTokenSubject.pipe(
            filter((token) => token !== ''),
            take(1),
            switchMap((token) => next.handle(this.addToken(req, token))),
        );
    }

    private addToken(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
        });
    }
}
