import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-web-layout',
    templateUrl: './web-layout.component.html',
    styleUrl: './web-layout.component.scss',
})
export class WebLayoutComponent implements OnInit {
    isSidebarOpen = false;

    @HostListener('window:resize', [])
    onResize(): void {
        this.checkScreenSize();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        // Check if the click event target is not within the sidebar
        if (window.innerWidth < 768) {
            if (
                !document.querySelector('#sidebar')!.contains(event.target as Node) &&
                !document.querySelector('#sidebar-toggle-btn')!.contains(event.target as Node)
            ) {
                this.isSidebarOpen = false;
            }
        }
    }

    ngOnInit(): void {
        this.checkScreenSize();
    }

    toggleSidebar(): void {
        if (window.innerWidth < 768) {
            this.isSidebarOpen = !this.isSidebarOpen;
        }
    }

    private checkScreenSize(): void {
        this.isSidebarOpen = window.innerWidth >= 768;
    }
}
