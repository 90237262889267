export class EnumOption {
    cssClass = '';
    label = '-';
    order? = 0;
}

export type EnumConstantInterface = Record<string, EnumOption>;

export class ListingFilterModel {
    currentPage = 0;
    filterBy = '';
    filterString = '';
    isOrderByDesc = false;
    orderBy = '';
    skip = 0;
    take = 10;
}
