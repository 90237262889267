import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { finalize } from 'rxjs';
import { LoadingDialogConfig } from './core/components/dialogs/dialog.model';
import { LoadingDialogComponent } from './core/components/dialogs/loading-dialog/loading-dialog.component';
import { ErrorResult } from './core/models/response.model';
import { AuthService } from './core/services/api/auth.service';
import { ApiErrorHandlerService } from './core/services/error/api-error-handler.service';
import { StorageService } from './core/services/storages/storage.service';
import { SIDEBAR_MENUS } from './layouts/web-layout/components/sidebar/constants/sidebar.constant';

@Component({
    selector: 'app-redirect',
    template: '',
})
export class MsalRedirectComponent implements OnInit {
    constructor(
        private authService: MsalService,
        private matDialog: MatDialog,
        private apiErrorHandlerService: ApiErrorHandlerService,
        private fnnAuthService: AuthService,
        private router: Router,
        private storageService: StorageService,
    ) {}

    ngOnInit(): void {
        this.authService.handleRedirectObservable().subscribe({
            next: (result: AuthenticationResult) => {
                if (result && result.idToken && result.accessToken) {
                    this.login(result.accessToken, result.idToken);
                }
            },
            error: (error) => console.error(error),
        });
    }

    private login(oAuthToken: string, azADAccessToken: string): void {
        const loaderRef = this.matDialog.open(LoadingDialogComponent, new LoadingDialogConfig());

        this.fnnAuthService
            .login(oAuthToken, azADAccessToken)
            .pipe(finalize(() => loaderRef.close()))
            .subscribe({
                next: () => {
                    if (this.storageService.isAuth()) {
                        const accessibleRoute = this.getAccessibleRoute();
                        if (accessibleRoute) {
                            this.router.navigate([accessibleRoute]);
                        }
                    }
                },
                error: (err: ErrorResult) => this.apiErrorHandlerService.handleError(err),
            });
    }

    //TODO
    // private getPermissions() {
    //     this.blockUI.start();

    //     this.userService
    //         .getDetail(this.storageService.userId)
    //         .pipe(finalize(() => this.blockUI.stop()))
    //         .subscribe({
    //             next: (response: any) => {
    //                 this.storageService.permissions = JSON.stringify(response.result.permissions);
    //             },
    //             error: (error) => console.error(error),
    //         });
    // }

    private getAccessibleRoute(): string | null {
        return (
            SIDEBAR_MENUS.find((route) => {
                return (
                    !route.requiredPermission ||
                    this.storageService.hasPermission(
                        route.requiredPermission.module,
                        route.requiredPermission.permission,
                    )
                );
            })?.url || null
        );
    }
}
