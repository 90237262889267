import { MatDialogConfig } from '@angular/material/dialog';

export class BaseDialogModel {
    message = '';
}

export class AlertPopupModel extends BaseDialogModel {
    iconUrl = '';
    okLabel = '';
}

export class ConfirmPopupModel extends BaseDialogModel {
    iconUrl = '';
    cancelLabel = '';
    confirmLabel = '';
}

export class AlertDialogConfig extends MatDialogConfig {
    override autoFocus = false;
    override disableClose = true;
    override panelClass = 'alert-dialog';
    override width = '450px';
}

export class LoadingDialogConfig extends MatDialogConfig {
    override disableClose = true;
    override panelClass = 'loader-panel';
}

export class CustomDialogConfig extends MatDialogConfig {
    override autoFocus = false;
    override hasBackdrop = true;
    override height = 'auto';
    override maxWidth = '100vw';
    override panelClass = 'custom-dialog';
    override width = '50%';
}
